import React, {useState, useEffect, useRef} from "react";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import {connect, useSelector, useDispatch} from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import moment from "moment";
import {subDomain, eventUrl, amountFormatterWithZero} from "../../utils";
import {
    getUserData,
    getAdminUserData,
    logoutAct,
    uploadUserForms,
    sendMesssageToAdmin,
    sendMesssageToAdminReply,
    createSignatureRequestAndGetUrl,
    userMessageShow,
    userSoftDeleteMessage,
    addToUserforms,
    getUserForm,
    sendMessageSuccessNotify,
    getUserforms,
} from "../../Actions/authActions";
import HelloSignUser from "../../Components/HelloSignUser";
import {withButtonLoader} from "../../helpers";
import CustomPopover from "../../Components/CustomPopover";
import CustomPopoverHelloSign from "../../Components/CustomPopoverHelloSign";
import ReplyMessagePopup from "../../Components/ReplyMessagePopup";
import "./dashboard.css";
import CustomePopUp from "../../Components/customePopUp";
import HistoryIconPopup from "./historyPopup";
import {DownArrow, HistoryIcon, SignOnline} from "../../Components/Icons";
import {Helmet} from "react-helmet";
import Socket from "../../socketHelper";
import CreateNewMessage from "../../Components/CreateNewMessage/createNewMessage";
import {executeGet} from "../../Api";
import {GET_EMBEDDED_SIGN_ERROR, GET_EMBEDDED_SIGN_SUCCESS} from "../../constants";
import UserHeader from "./UserHeader";
import UserForms from "./UserForms";


const Index = (props) => {
    const {match} = props || {};
    // const { params } = match || {};
    const params = useParams();
    const subdomain = params.subdomain || subDomain();

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const isLoginLoad = useSelector((state) => state.auth.isLoginLoad);
    const user = useSelector((state) => state.auth.user);
    const adminUser = useSelector((state) => state.auth.adminUser);
    const logoUrl = useSelector((state) => state.auth.adminUser?.logoUrl);
    const messages = useSelector((state) => state.auth.messages);
    const transactionSuccess = useSelector((state) => state.auth.transactionSuccess);

    const logoName = logoUrl;
    const themeColor1 = get(adminUser, "theme.themeColor1");
    const themeColor2 = get(adminUser, "theme.themeColor2");

    let globalCustomCSSWithTheme;

    globalCustomCSSWithTheme = `.dropzone-loader .css-15llytj span {background: ${themeColor1}} .dropzone-loader:hover .css-15llytj span{background: ${themeColor1}} .sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}} .amount-label {color:${themeColor1} !important} .message-received-text {color:${themeColor1}}.message-received-text:hover {color: ${themeColor1}} .custom-input-border-css {border: 1px solid ${themeColor2 ? themeColor2 : "#94bbad"}}  .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2} !important; border: ${themeColor2} } .sos-content .custom-input-border-css {border: 1px solid ${themeColor2 ? themeColor2 : "#94bbad"} `;
    const {patientPayer} = user || {};


    const messageToAdminSuccess = useSelector((state) => state.auth.messageToAdminSuccess);
    const userId = JSON.parse(localStorage.getItem("user"))?.id || "";
    const [showPopUp, setShowPopUp] = useState(false);
    const [messageLoad, setMessageLoad] = useState(false);

    const [target, setTarget] = useState(null);
    const [msgDataDelete, setMsgDataDelete] = useState({});
    const [patientPayerInfo, setPatientPayerInfo] = useState([]);
    const [isOpenReply, setIsOpenReply] = useState(false);
    const [msgConvo, setMsgConvo] = useState({});
    const [errorMsg, setErrorMsg] = useState("");

    const [sendMsg, setSendMsg] = useState("");
    const [sendLoader, setSendLoader] = useState(false);
    const [errorReplyToAdmin, setErrorReplyToAdmin] = useState("");
    const [contactOfficeMessage, setContactOfficeMessage] = useState("");
    const [isOpenMsg, setIsOpenMsg] = useState(false);
    const [totalAmountExssist, setTotalAmountExssist] = useState("");
    const [messageId, setMessageId] = useState("");


    const [index, setIndex] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);

    const [isMultipleProviders, setIsMultipleProviders] = useState(false);

    const [popUpMessageLoad, setPopUpMessageLoad] = useState(false);

    let currentPage;
    if(location.pathname.includes('home')) {
        currentPage = "dashboard";
    } else if (location.pathname.includes('forms')) {
        currentPage = "forms";
    }

    const [selectedTab, setSelectedTab] = useState(currentPage);

    useEffect(() => {
        if (transactionSuccess) {
            const PayerUser = JSON.parse(localStorage.getItem("user"));
            setPatientPayerInfo(PayerUser.patientPayer);
        }
    }, [transactionSuccess]);

    useEffect(() => {
        const PayerUser = JSON.parse(localStorage.getItem("user"));
        setPatientPayerInfo(PayerUser.patientPayer);
    }, []);

    useEffect(() => {
        if (!isLoginLoad) {
            dispatch(getAdminUserData(subdomain));
            dispatch(getUserData(subdomain));
            dispatch(getUserforms(subdomain));
        }
    }, [!isLoginLoad]);

    useEffect(() => {
        if (Socket) {
            Socket.on(`adminUpdated_${subdomain}`, () => {
                dispatch(getAdminUserData(subdomain));
            });
            Socket.on(`userIntakeDelete_${subdomain}`, () => {
                dispatch(getUserforms(subdomain));
            });
            Socket.on(`sendMessageToUser_${subdomain}`, () => {
                if (userId) {
                    props
                        .userMessageShow(subdomain, userId)
                        .then(() => {
                        })
                        .catch((error) => {
                            console.log("error", error);
                        });
                }
            });
        }
    }, [Socket]);

    useEffect(() => {
        if (messages) {
            setMsgConvo(messages);
        }
    }, [messages]);

    useEffect(() => {
        return () => {
            if (Socket) {
                Socket.off(`adminUpdated_${subdomain}`, () => {
                });
                Socket.off(`userIntakeDelete_${subdomain}`, () => {
                });
                Socket.off(`sendMessageToUser_${subdomain}`, () => {
                });
            }
        };
    }, [Socket]);

    useEffect(() => {
        //get messages
        if (user && user.id) {
            props
                .userMessageShow(subdomain, user.id)
                .then(() => {
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }, [user]);

    useEffect(() => {
        //get messages
        if (!messageLoad) {
            if (user && user.id) {
                props
                    .userMessageShow(subdomain, user.id)
                    .then(() => {
                        setMessageLoad(true);
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setMessageLoad(true);
                    });
            }
        }
    }, [messageLoad]);

    useEffect(() => {
        // returned function will be called on component unmount
        return () => {
            setMessageLoad(false);
        };
    }, []);

    useEffect(() => {
        if (patientPayer && patientPayer.length > 0) {
            setIsMultipleProviders((() => {
                for (const ptPayer of patientPayer) {
                    if (
                        ptPayer.providerNumber !== patientPayer[0].providerNumber
                        || ptPayer.providerFirst !== patientPayer[0].providerFirst
                        || ptPayer.providerLast !== patientPayer[0].providerLast
                    ) {
                        return true;
                    }
                }
                return false;
            })());
        }
    }, [patientPayer]);


    const togglePopupMsg = (isOpenMsg) => {
        setIsOpenMsg(!isOpenMsg);
    };

    const onCloseModal = () => {
        setIsOpenMsg(false);
        dispatch(sendMessageSuccessNotify());
    };

    const onMakePayment = () => {

        if (totalAmountsPaid() === 0) {
            setTotalAmountExssist("Please enter an amount to pay.");
        } else {
            localStorage.setItem('state', JSON.stringify({patientPayerInfo}));
            navigate(`${eventUrl(subdomain)}payments`);
        }
    };

    const onPaymentMethods = () => {
        const noBalancePatientPayerInfo = patientPayerInfo.map(o => {
            return {
                ...o, accountBalance: "0"
            }
        });
        localStorage.setItem('state', JSON.stringify({noBalancePatientPayerInfo}));
        navigate(`${eventUrl(subdomain)}payments`);
    };


    const totalAmounts = () => {
        const total = sumBy(map(patientPayer, (i) => ({
            ...i, accountBalance: parseFloat(i.accountBalance) || 0,
        })), "accountBalance");
        return total;
    };

    const toggleReplyPopup = () => {
        setIsOpenReply(!isOpenReply);
    };

    const handleContactOfficeMessage = (e) => {
        setContactOfficeMessage(e.target.value);
    };

    const handleSubmitMessage = () => {
        if (contactOfficeMessage.trim("")) {
            setSendLoader(true);
            const data = {
                msg: contactOfficeMessage, userId: user.id.toString(),
            };
            props
                .sendMesssageToAdmin(data, subdomain)
                .then((resp) => {
                    setSendLoader(false);
                    if (resp && resp.success) {
                        setErrorMsg("Message sent successfully");
                        setContactOfficeMessage("");
                    } else {
                        setErrorMsg("Message not sent");
                    }
                    toggleReplyPopup();
                })
                .catch((error) => {
                    setSendLoader(false);
                    console.log({error});
                });
        }
    };


    const handleClick = (e) => {
        e.target.select();
    };

    const showPopOver = (event, data) => {
        setShowPopUp(!showPopUp);
        setTarget(event.target);
        setMsgDataDelete(data);
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
    };


    const onRemove = () => {
        props.userSoftDeleteMessage(msgDataDelete._id).then((resp) => {

            if (resp) {
                handleClosePopUp();
                props.userMessageShow(subdomain, user.id).then(() => {
                    // setMessageAdmin(resp.payload);
                });
            }
        });
    };


    const onSendMessage = () => {
        if (sendMsg.trim("")) {
            setPopUpMessageLoad(true);
            const data = {
                msg: sendMsg, userId: user.id.toString(), messageId,
            };
            props.sendMesssageToAdminReply(data, subdomain).then((resp) => {
                setMsgConvo(resp?.message_reponse);
                setSendMsg("");
                setPopUpMessageLoad(false);
                setErrorMessage(true);
            });
        } else {
            setErrorReplyToAdmin("Message should not be empty");
            setErrorMessage(true);
        }
    };
    const onAmountChangeHandler = (e, index) => {
        const inputValues = [...patientPayerInfo];
        const value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(".", "x")
            .replace(/\./g, "")
            .replace("x", ".");
        if (inputValues && inputValues[index]) {
            inputValues[index].accountBalance = value;
        }
        setPatientPayerInfo(inputValues);
    };
    const totalAmountsPaid = () => {
        const total = sumBy(map(patientPayerInfo, (i) => ({
            ...i, accountBalance: i ? Math.max(parseFloat(i.accountBalance), 0) : 0,
        })), "accountBalance");
        return total;
    };


    return (<>
        <div className="wrapper">
            <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
            <div className="sos-card-login home-patient-box">
                <UserHeader dispatch={dispatch} subdomain={subdomain} selectedTab={selectedTab}
                            logoName={logoName}
                            setSelectedTab={setSelectedTab} themeColor1={themeColor1}></UserHeader>


                {selectedTab === 'dashboard' &&
                    <div className="mt-3 sos-content soscontent-mobileview soscontent-background flex-space-between">
                        <div className="homeexisting-patient full-width">
                            <div
                                className="homeexisting-patient-box position-relative account-card account-balance-card mt-2 m-auto">
                                <div className="d-flex hommeexisting-large-text hommeexisting-hoiz-text">
                                    <div className="pay-left">
                                        Account Balance
                                    </div>
                                    <div className="pay-center">
                                        Payment Amount
                                    </div>
                                </div>

                                {patientPayerInfo && patientPayerInfo.length > 0 ? (
                                    <div className="hommeexisting-inner-card">
                                        <div className="home-ammount-balance upload-doc-scroll">
                                            {map(patientPayerInfo, (a, index) => {
                                                return (<span key={index}>

                                                <div
                                                    className="d-flex align-items-center hommeexisting-large-inner mt-1">
                                                <label
                                                    className="amount-label">{
                                                    `$${amountFormatterWithZero((patientPayer && patientPayer[index]?.accountBalance) || "")}`
                                                }</label>
                                                <input
                                                    type="text"
                                                    className="form-control amount-input custom-input-border-css"
                                                    // id="accountBalance"
                                                    aria-describedby="emailHelp"
                                                    placeholder="$0.00"
                                                    defaultValue={`$${amountFormatterWithZero(Math.max(a.accountBalance, 0))}`}
                                                    onClick={(e) => handleClick(e)}
                                                    onChange={(e) => onAmountChangeHandler(e, index)}
                                                />
                                                <p className="">
                                                    <em>{a && `${a.patientFirst || ""} ${a.patientLast || ""}`}</em>
                                                </p>

                                                </div>
                                               <div className="mt-1 provider-style credit-balance">
                                                {a.accountBalance < 0 && `$${amountFormatterWithZero(-1 * a.accountBalance) || ""} CREDIT BALANCE`}
                                               </div>

                                                    <div className="mt-1 provider-style">
                                                  {a && isMultipleProviders && (a.providerFirst > "" || a.providerLast > "") &&
                                                      `Provider: ${a.providerFirst || ""} ${a.providerLast || ""}`
                                                  }
                                                </div>
                                                <div className="divider"/>

                                            </span>);
                                            })}
                                            <div className="mt-1 provider-style">
                                                {patientPayer && patientPayer.length > 0 && !isMultipleProviders &&
                                                    (patientPayer[0].providerFirst > "" || patientPayer[0].providerLast > "") &&
                                                    `Provider: ${patientPayer[0].providerFirst || ""} ${patientPayer[0].providerLast || ""}`
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex hommeexisting-large-inner-text">
                                            <p data-cy="total">Total</p>
                                            <p data-cy="total-value">${amountFormatterWithZero(totalAmountsPaid())}</p>
                                        </div>
                                        {totalAmountExssist && (
                                            <span className="color-text">{totalAmountExssist}</span>)}
                                        <div className="divider"/>
                                        <div className="d-flex justify-content-end ">
                                            {(adminUser.displayAddPaymentMethod || user && user.paymentMethods && user.paymentMethods.length > 0) &&
                                                <button
                                                    type="submit"
                                                    data-cy="make-payment-btn"
                                                    className="sos-custom-btn sos-secondary-btn-css mt-5 mr-16"
                                                    onClick={() => onPaymentMethods()}
                                                >
                                                    Payment Methods
                                                </button>}
                                            <button
                                                type="submit"
                                                data-cy="make-payment-btn"
                                                className="sos-custom-btn sos-custom-btn-css mt-5"
                                                onClick={() => onMakePayment()}
                                            >
                                                Make a Payment
                                            </button>
                                        </div>
                                    </div>) : (<div className="when-you-have-an-amo no-amount">
                                    No accounts found for payment.
                                </div>)}
                                {/*<div className="hommeexisting-img">*/}
                                {/*    <img src="../images/payment-icon.png"/>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className="homeexisting-patient full-width">
                            <div>
                                <CreateNewMessage
                                    titleText={"Contact the Office"}
                                    subtitleText={"Your message will be sent to our office."}
                                    handleSubmitMessage={handleSubmitMessage}
                                    sendLoader={sendLoader}
                                    isOpenReply={isOpenReply}
                                    errorMsg={errorMsg}
                                    toggleReplyPopup={toggleReplyPopup}
                                    contactOfficeMessage={contactOfficeMessage}
                                    handleContactOfficeMessage={handleContactOfficeMessage}
                                />

                                {messages && map(messages, (i, index) => {

                                    const clonedConvo = i && i.messages && i.messages.length && JSON.parse(JSON.stringify(i));

                                    const lastAdminMsg = clonedConvo && clonedConvo.messages
                                        .reverse()
                                        .find((m) => m.sendBy !== "user");

                                    if (lastAdminMsg) {
                                        return (<div
                                            className="homeexisting-patient-box account-card  position-relative"
                                            key={index}
                                        >
                                            <div className="hommeexisting-large-text hommeexisting-hoiz-text">
                                                Received Messages
                                            </div>
                                            <div key={index} className="messages-scroll">
                                                <div className="message-received">
                                                    <div className=" col-11">
                                                        <p
                                                            className="mb-0 from-office"
                                                            onClick={() => togglePopupMsg()}
                                                        >
                                                            <b>FROM</b> : Office
                                                        </p>
                                                        <Link
                                                            to="#"
                                                            className="message-received-text message-display"
                                                            onClick={() => {
                                                                setMessageId(i._id);
                                                                setSendMsg('');
                                                                togglePopupMsg();
                                                                setMsgConvo(i);
                                                                setIndex(index);
                                                            }}
                                                        >
                                                            {lastAdminMsg.msg}
                                                        </Link>

                                                        <p>
                                  <span className="message-received-date">
                                    {moment(lastAdminMsg.createdAt).format("MM/DD/YY")}
                                  </span>
                                                            |
                                                            <span className="message-received-time">
                                    {moment(lastAdminMsg.createdAt).format("hh:mm A")}
                                  </span>
                                                        </p>
                                                    </div>
                                                    <div className="col-1">
                                                        <div className="close-icon-message">
                                                            <img
                                                                src="/images/fi-rr-cross.svg"
                                                                alt="cross"
                                                                onClick={(e) => showPopOver(e, i)}
                                                            />
                                                            <CustomPopover
                                                                title={"Are you sure you want to remove this conversation?"}
                                                                show={showPopUp}
                                                                target={target}
                                                                onRemove={onRemove}
                                                                close={handleClosePopUp}
                                                                setShowPopUp={handleClosePopUp}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                    }
                                })}
                            </div>
                        </div>
                    </div>}
                {selectedTab === 'forms' &&
                    <UserForms user={user} adminUser={adminUser} uploadUserForms={props.uploadUserForms}
                               subdomain={subdomain} themeColor1={themeColor1}></UserForms>}
                <ReplyMessagePopup
                    openModal={isOpenMsg}
                    msg={errorMsg}
                    onCloseModal={onCloseModal}
                    count={index}
                    isReply={true}
                    message={msgConvo}
                    userId={user.id}
                    onSendSuccess={onSendMessage}
                    setSendMsg={setSendMsg}
                    sendMsg={sendMsg}
                    sendLoader={popUpMessageLoad}
                    errorReplyToAdmin={errorReplyToAdmin}
                    payerId={user && user.payerId}
                    sendMessageNotify={messageToAdminSuccess}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    setErrorReplyToAdmin={setErrorReplyToAdmin}
                />

            </div>
        </div>
    </>);
};

const mapDispatchToProps = {
    sendMesssageToAdmin, userSoftDeleteMessage, userMessageShow, sendMesssageToAdminReply, getUserForm, uploadUserForms,
};
export default connect(null, mapDispatchToProps)(Index);
