import React, {useEffect, useRef, useState} from "react";
import {SoButton} from "../../UI/Button/so-button";
import "./create-sign-document.css";
import axios from "../../../api/axiosApi";
import {DocusealBuilder} from "@docuseal/react";

export const CreateSignDocument = ({file, close}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [token, setToken] = useState(null);
    const [slug, setSlug] = useState(null);

    useEffect(() => {
        setTimeout(async () => {        
            try {
                let response = await axios.get('documents/enable?url=' + file.url + '&name=' + file.originalname + '&documentId=' + file._id, {
                    headers: {
                        'micro-service': 'appointment',
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                });
                setToken(response.data.token);
            } catch (e) {
                console.error(e);
            }
        });
    }, []);

    const upload = async () => {
        if (!slug) {
            close(true);
            return;
        }
        setIsUploading(true);
        await axios.get(`documents/enable-esignature?slug=${slug}&url=${file.url}`, {
            headers: {
                'micro-service': 'appointment',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        });
        close(true);
        setIsUploading(false);
    };

    return (<>
         <div className={'header'}>
                <h4>Add fields to {file.originalname.slice(0, 20)}</h4>
                <div className={'header-action'}>
                    <div>
                        <button
                            className="sos-custom-btn-new-message sos-secondary-btn-css so-btn"
                            onClick={() => close(false)}
                        >
                            Cancel
                        </button>
                    </div>
                    <div>
                        <SoButton isLoading={isUploading}
                                  onClick={() => upload()}
                                  text={'Save'}></SoButton>
                    </div>
                </div>
            </div>
    <div className={'document-container'}>
                <div className={'panel-container'}>
                    <div>
                        <p><b>Instructions:</b> Drag and drop fields from the right column onto the document.</p>
                    </div>
                    <div className={'viewer-container'}>
                        {token && <DocusealBuilder
                            host={'docuseal.sosoftpay.com'}
                            fieldTypes={['text', 'signature', 'initials', 'date', 'number', 'checkbox','radio','select']}
                            token={token}
                            // externalId={file._id}
                            onSave={(data) => {
                                setSlug(data.slug);
                            }}
                            customCss={`
                    #title_container{display: none;}
                    .dropdown {color: #000;}
                    .sticky {color: #000;}
                    div > div > div.flex.sticky.top-0.z-50.space-x-2.items-center.bg-yellow-100.p-2.border-y.border-yellow-200 {display: none;}
                    #pages_container > div > div.flex.space-x-2.pb-2.mb-2.border-b.border-base-300.border-dashed {display: none;}
                    #pages_container > div > div.pb-4.space-y-2 {display: none;}
                    #main_container{min-height: 100vh;color: #000;}
                    #add_document_button{display: none;}
                    `}/>}
                    </div>
                </div>
            </div>
    </>
    );
};
